import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = (): JSX.Element => (
  <Layout>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h3 className="has-text-weight-semibold is-size-2">Siden kunne ikke findes</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
